import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'

const PageHeader = ({ activeCategory, setActiveCategory }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1>Showcase</h1>
            <p>Check out these Atlas powered best-practice maps</p>
            {/* <p>New to Atlas? Get started with this handy guide.</p> */}
          </div>
        </div>

        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${activeCategory === 'Explore all' &&
              styles.active}`}
            onClick={() => setActiveCategory('Explore all')}
          >
            Explore
          </div>
          <div
            className={`${styles.tab} ${activeCategory === 'Environment' &&
              styles.active}`}
            onClick={() => setActiveCategory('Environment')}
          >
            Environment
          </div>
          <div
            className={`${styles.tab} ${activeCategory === 'Infrastructure' &&
              styles.active}`}
            onClick={() => setActiveCategory('Infrastructure')}
          >
            Infrastructure
          </div>
          <div
            className={`${styles.tab} ${activeCategory === 'Society' &&
              styles.active}`}
            onClick={() => setActiveCategory('Society')}
          >
            Society
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
