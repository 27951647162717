import React, { useState, useEffect } from 'react'
import { Select, Skeleton, TextInput } from '@mantine/core'
import styles from './MapGrid.module.scss'
import Button from '../common/Button'
import {
  ArrowTrendingUpIcon,
  BookmarkIcon,
  BuildingStorefrontIcon,
  ClockIcon,
  CpuChipIcon,
  GlobeEuropeAfricaIcon,
  MagnifyingGlassIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  UserGroupIcon,
  UserIcon
} from '@heroicons/react/20/solid'
import { Link } from 'gatsby'
import { formatDateToDaysAgo } from '../../utils/format'

const categories = [
  {
    label: 'Environment',
    icon: <GlobeEuropeAfricaIcon />
  },
  {
    label: 'Infrastructure',
    icon: <BuildingStorefrontIcon />
  },
  {
    label: 'Technology',
    icon: <CpuChipIcon />
  },
  {
    label: 'Society',
    icon: <UserGroupIcon />
  }
]

const MapGrid = ({ maps, activeCategory }) => {
  const [searchInput, setSearchInput] = useState('')

  const getFilteredMaps = maps => {
    let filteredMaps = [...maps]

    // Filter by category
    if (activeCategory === 'Featured') {
      filteredMaps = maps.filter(map => map.frontmatter.featured === true)
    } else if (activeCategory === 'Trending') {
      filteredMaps = maps.filter(map => map.frontmatter.trending === true)
    } else if (activeCategory === 'Newest') {
      filteredMaps = maps.filter(map => map.frontmatter.newest === true)
    } else if (activeCategory !== 'Explore all') {
      filteredMaps = maps.filter(map => map.frontmatter.tag === activeCategory)
    }

    // Filter by search input
    if (searchInput !== '') {
      const searchInputLC = searchInput.toLowerCase()
      filteredMaps = filteredMaps.filter(map =>
        map.frontmatter.title.toLowerCase().includes(searchInputLC)
      )
    }

    return filteredMaps
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div>
            <Skeleton visible={!maps}>
              <div className={styles.grid}>
                {getFilteredMaps(maps).map(m => (
                  <GridItem
                    title={m.frontmatter.title}
                    link={m.frontmatter.link}
                    tag={m.frontmatter.tag}
                    image={m.frontmatter.featuredImage}
                    featured={m.frontmatter.featured}
                    trending={m.frontmatter.trending}
                    newest={m.frontmatter.newest}
                    created={m.frontmatter.created}
                    creator={m.frontmatter.creator}
                    description={m.frontmatter.description}
                  />
                ))}
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
    </>
  )
}

const getTagTextColor = tag => {
  if (tag === 'Environment') {
    return '#1E3023'
  } else if (tag === 'Infrastructure') {
    return '#1E2830'
  } else if (tag === 'Technology') {
    return '#2D1E30'
  } else if (tag === 'Society') {
    return '#302D1E'
  } else {
    return '#1E3023'
  }
}

const getTagBackgroundColor = tag => {
  if (tag === 'Environment') {
    return '#D7F3DE'
  } else if (tag === 'Infrastructure') {
    return '#D7E7F3'
  } else if (tag === 'Technology') {
    return '#E8D7F3'
  } else if (tag === 'Society') {
    return '#F3F0D7'
  } else {
    return '#D7F3DE'
  }
}

const GridItem = ({
  id,
  title,
  link,
  tag,
  image,
  featured,
  trending,
  newest,
  created,
  creator,
  description
}) => {
  const [hover, setHover] = useState(false)
  return (
    <a href={link} target="_blank">
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {/* {featured && (
          <div className={styles.featured}>
            <div className={styles.ribbon} />
          </div>
        )} */}
        <div className={`${styles.cardImage} ${hover && styles.hovered}`}>
          <img src={image} alt={title} />
        </div>
        <div className={`${styles.cardContent} ${hover && styles.hovered}`}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className={styles.bottomContent}>
          <UserIcon height={20} style={{ marginBottom: '-0.3rem' }} /> {creator}
          {tag && (
            <span
              className={styles.date}
              style={{
                color: getTagTextColor(tag),
                backgroundColor: getTagBackgroundColor(tag)
              }}
            >
              {tag}
            </span>
          )}
        </div>
      </div>
    </a>
  )
}

export default MapGrid
